import { Injectable } from '@angular/core';
import { Pensao } from '@sgprev-entities';
import { TipoPensao } from '@sgprev-enums';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AppService {
    private tipoPensaoObservable = new Subject<any>();
    public tipoPensao!: TipoPensao;
    public tipoPensaoObservable$ = this.tipoPensaoObservable.asObservable();

    private dadosPensaoObservable = new BehaviorSubject<Pensao | null>(null);
    public dadosPensao!: Pensao;
    public dadosPensaoObservable$ = this.dadosPensaoObservable.asObservable();

    private tabViewActiveIndexObservable = new Subject<number>();
    public tabViewActiveIndex!: number;
    public tabViewActiveIndexObservable$ = this.tabViewActiveIndexObservable.asObservable();

    constructor() {
    }

    updatePensao(pensao: TipoPensao) {
        this.tipoPensao = pensao;
        this.tipoPensaoObservable.next(this.tipoPensao);
    }

    updateDadosPensao(dadosPensao: Pensao) {
        this.dadosPensao = dadosPensao;
        this.dadosPensaoObservable.next(dadosPensao);
    }

    updateTabViewActiveIndex(tabViewActiveIndex: number) {
        this.tabViewActiveIndex = tabViewActiveIndex;
        this.tabViewActiveIndexObservable.next(this.tabViewActiveIndex);
    }

}
