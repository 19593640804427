import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'environments/environment';
import { VinculoFuncionalDto } from "../dtos/vinculo-funcional.dto";
import { VinculoMediaDto } from "../dtos/vinculo-media.dto";

@Injectable({
  providedIn: 'root'
})
export class SgprevPensaoService {
  private apiUrl = `${environment.API_URL_SGPREV}`;

  constructor(private http: HttpClient) { }

  public buscarDadosCalculo(isnOrgao: string, matricula: string, dataObito: Date, recalculo: boolean): Observable<any> {
    const dataFormatada = this.getDataFormatada(dataObito)

    if (recalculo)
      return this.http.get<any>(`${this.apiUrl}/pensao/recalculo/orgao/${isnOrgao}/matricula/${matricula}/dataObito/${dataFormatada}`);
    else
      return this.http.get<any>(`${this.apiUrl}/pensao/orgao/${isnOrgao}/matricula/${matricula}/dataObito/${dataFormatada}`);
  }

  public salvarBeneficioMedio(isnOrgao: string, matricula: string, dadosBeneficio: any) {
    return this.http.post<any>(`${this.apiUrl}/pensao/salvar-beneficio-medio?orgaoId=${isnOrgao}&matricula=${matricula}`, dadosBeneficio);
  }

  public incluirMatriculasMedia(isnOrgao: string, matricula: string, vinculosMediaDto: VinculoMediaDto[]) {
    return this.http.post<any>(`${this.apiUrl}/pensao/incluir-matriculas-na-media?orgaoId=${isnOrgao}&matricula=${matricula}`, vinculosMediaDto);

  }

  public buscarDescricaoMedia(isnOrgao: string, matricula: string) {
    return this.http.get(`${this.apiUrl}/regra/campo-media-regra?orgaoId=${isnOrgao}&matricula=${matricula}`, { responseType: 'text' });
  }

  public buscarVinculosFuncionais(isnOrgao: string, matricula: string) {
    return this.http.get<VinculoFuncionalDto[]>(`${this.apiUrl}/vinculo/matriculas-validas-para-incluir-na-media/matricula/${matricula}/orgao/${isnOrgao}`);
  }

  private getDataFormatada(data: Date) {
    let mm = data.getMonth() + 1;
    let dd = data.getDate();

    let ddfStr = '' + dd;
    let mmStr = '' + mm;

    if (dd < 10)
      ddfStr = '0' + dd;

    if (mm < 10)
      mmStr = '0' + mm;

    return data.getFullYear() + '-' + mmStr + '-' + ddfStr;
  }
}
