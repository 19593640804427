import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Estado } from '@sgprev-entities';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadoPensaoService {

  private apiUrl = environment.API_URL;

  constructor(private httpClient: HttpClient) { }

  public buscarEstados(): Observable<Estado[]> {
    return this.httpClient.get<Estado[]>(`${this.apiUrl}/estado`);
  }

  public buscarEstadosPorSigla(sigla: string): Observable<Estado> {
    return this.httpClient.get<Estado>(`${this.apiUrl}/estado/por-sigla?sigla=${sigla}`);
  }
}
