import { DataUtil } from '../utils/data.util';


export class DetalheBeneficio {
  id!: number;
  dataRemuneracao!: Date;
  valorRemuneracao!: number;
  valorPercentContribuicao!: number;
  valorContribuicao!: number;
  valorFatorAtualizacao!: number;
  valorAtualizado!: number;
  valorAtualizadoCriticado!: number;
  flagValorConsiderado!: boolean;
  justificativaAlteracao!: any;
  origemValor!: string;
  correcaoOrigem!: boolean;
  idRepassePrevidenciario!: any;
  codDetalhamentoTemp!: any;

  constructor(init?: Partial<DetalheBeneficio>) {
    Object.assign(this, init);

    if (init?.dataRemuneracao)
      this.dataRemuneracao = DataUtil.newDateOnly(init.dataRemuneracao);

  }
}
